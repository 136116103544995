import React, { useState } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { useForm } from "react-hook-form"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  ClockIcon,
} from "@heroicons/react/24/solid"

const Contact = ({ data }) => {
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  const images = {
    contactbg: convertToBgImage(getImage(data.contactbg)),
    contactform: convertToBgImage(getImage(data.contactform)),
    // bgvegetable: convertToBgImage(getImage(data.vegetable)),

    // gardening: getImage(data.gardening),
    // grow: getImage(data.grow),
    // vegetable: getImage(data.vegetable),
  }
  const blog = []

  const [readMore, setReadMore] = useState(true)

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const sendGridFirebase = async data => {
    fetch(
      "https://us-central1-watawala-website.cloudfunctions.net/contactFormRequest",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      }
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
  }

  const onSubmit = async data => {
    setLoading(true)
    // captionRef.current.value = ""

    try {
      // await submitContactRequest(data)
      await sendGridFirebase(data)
      // parentState(data)
      setLoading(false)
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 5000)
      setSubmitError(null)
      reset()
      console.log("submited")
    } catch (error) {
      setLoading(false)
      setSuccess(false)
      setSubmitError("Submit Error occurred!")
      console.log(error.message)
    }
  }
  return (
    <Layout>
      <Seo title="Contact | Watawala" />
      <section>
        <BackgroundImage
          {...images.contactbg}
          className="relative overflow-hidden"
        >
          <div className="h-screen flex items-center w-full  top-0 z-10">
          <h1 hidden>"Elevate Your Well-being with the Essence of Nature: Embrace the Herbal Tea Experience"</h1>
            <div className="container md:px-20 px-10 mx-auto">
              <div className="font-bold text-5xl text-center lg:text-left md:text-8xl text-white">
                Contact us
              </div>
              <div className="mt-3 text-xl font-semibold text-center lg:text-left text-white">
                Let us know how we can help and setup a time to chat with us!
              </div>
              <div className="uppercase font-light text-sm tracking-widest">
                <div className="mt-5 flex justify-center lg:justify-start">
                  <a href="#form">
                    <button
                      type="submit"
                      className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-semibold rounded-full px-8 text-lg py-4 hover:border-black duration-200 flex gap-3 items-center cursor-pointer"
                    >
                      Contact Now
                      <div>
                        <ArrowLongRightIcon className="h-4 w-4 stroke-black" />
                      </div>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>

        <div
          className="bg-gray-100	  py-6 px-6   sm:py-10  sm:px-10    md:py-20   md:px-20 "
          id="form"
        >
          <div className="container px-2 mx-auto py-2    rounded-3xl	   bg-white	 ">
            <div class="grid grid-cols-1 gap-0  lg:grid-cols-3    lg:gap-4    overflow-hidden relative  ">
              <BackgroundImage
                {...images.contactform}
                className="rounded-3xl  px-4  py-4  lg:px-10  lg:py-10     overflow-hidden relative"
              >
                <div className="text-black	font-semibold	text-lg	 uppercase">
                  Contact information
                </div>
                <div className="text-black	font-normal	    text-base	mt-4 mb-8">
                  Fill up the form or email us. Our team will get back to you
                  within 24 hours.
                </div>

                {/* Icon Row */}
                <div className="flex gap-5 mb-8  ">
                  <div>
                    <div className="bg-yellow  rounded-full  p-4">
                      <StaticImage
                        src="../images/contact/bi_telephone.png"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="text-black ml-1">
                    <div className="font-semibold">Telephone</div>
                    +94 114 741 662
                  </div>
                </div>
                {/* Icon Row END */}

                {/* Icon Row */}
                <div className="flex gap-5 mb-8">
                  <div>
                    <div className="bg-yellow  rounded-full  p-4">
                      <StaticImage
                        src="../images/contact/email.png"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="text-black ml-1 ">
                    <div className="font-semibold">Email</div>
                    <div className="break-all">
                      <a href="mailto:watawala@sunshineholdings.lk">
                        watawala@sunshineholdings.lk
                      </a>
                    </div>
                  </div>
                </div>
                {/* Icon Row END */}

                {/* Icon Row */}
                <div className="flex gap-5 mb-8  ">
                  <div>
                    <div className="bg-yellow  rounded-full  p-4 ">
                      <StaticImage
                        src="../images/contact/location.png"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="text-black ml-1  ">
                    <div className="font-semibold    ">Address</div>
                    No.60, Dharmapala Mawatha, <br />
                    Colombo 03, <br />
                    Sri Lanka.
                  </div>
                </div>
                {/* Icon Row END */}

                <div className="flex gap-5   lg:absolute lg:bottom-10 ">
                  <a
                    href="https://www.facebook.com/WatawalaTeaSriLanka/"
                    target="_blank"
                    className="group"
                  >
                    <div className="group-hover:hidden block">
                      <StaticImage
                        src="../images/icons/social-black/facebook.png"
                        className="w-10"
                        alt="Watawala"
                      />
                    </div>
                    <div className="group-hover:block hidden">
                      <StaticImage
                        src="../images/icons/social/facebookYellow.png"
                        className="w-10"
                        alt="Watawala"
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/watawalatea/"
                    target="_blank"
                    className="group"
                  >
                    <div className="group-hover:hidden block">
                      <StaticImage
                        src="../images/icons/social-black/insta.png"
                        className="w-10"
                        alt="Watawala"
                      />
                    </div>
                    <div className="group-hover:block hidden">
                      <StaticImage
                        src="../images/icons/social/instagramYellow.png"
                        className="w-10"
                        alt="Watawala"
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCYWRbGloP_sXJ_BsiDqY00w"
                    target="_blank"
                    className="group"
                  >
                    <div className="group-hover:hidden block">
                      <StaticImage
                        src="../images/icons/social-black/youtube.png"
                        className="w-10"
                        alt="Watawala"
                      />
                    </div>
                    <div className="group-hover:block hidden">
                      <StaticImage
                        src="../images/icons/social/youtubeYellow.png"
                        className="w-10"
                        alt="Watawala"
                      />
                    </div>
                  </a>
                </div>
              </BackgroundImage>

              <div className="col-span-2  px-2  py-6    md:py-14 lg:py-14">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="text-black   font-semibold   text-base	mb-2">
                    Your Name*
                  </div>
                  <div className="mb-8">
                    <input
                      type="text"
                      className="rounded-3xl  border-slate-600	focus:outline-yellow duration-100	border-2	w-full py-4   px-4"
                      placeholder="Enter your name"
                      {...register("name", {
                        required: true,
                        pattern: {
                          value: /^[a-z ,.'-]+$/i,
                          message: "You Can Only Use Letters",
                        },
                      })}
                      name="name"
                    />
                    {errors.name ? (
                      <>
                        {errors.name.message ? (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              {errors.name.message}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              Name cannot be empty
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="text-black   font-semibold   text-base	mb-2">
                    Your Email*
                  </div>
                  <div className="mb-8">
                    <input
                      type="text"
                      className="rounded-3xl  border-slate-600	focus:outline-yellow duration-100	border-2	w-full py-4   px-4"
                      placeholder="Enter your email"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Email address is not valid",
                        },
                      })}
                      name="email"
                    />
                    {errors.email ? (
                      <>
                        {errors.email.message ? (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              {errors.email.message}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              Email cannot be empty
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div className="text-black   font-semibold   text-base	mb-2">
                    Your contact number*
                  </div>
                  <div className="mb-8">
                    <input
                      type="text"
                      className="rounded-3xl  border-slate-600	focus:outline-yellow duration-100	border-2	w-full py-4   px-4 "
                      placeholder="Enter your contact number"
                      {...register("number", {
                        required: true,
                        pattern: {
                          value: /^(\+\d{1,3}[- ]?)?\d{10}$/i,
                          message: "Mobile number is not valid",
                        },
                      })}
                      name="number"
                    />
                    {errors.number ? (
                      <>
                        {errors.number.message ? (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              {errors.number.message}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-xs text-red-400 font-firs-light mt-1">
                              Number cannot be empty
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>

                  <div className="text-black   font-semibold   text-base	mb-2">
                    Message*
                  </div>
                  <div className="mb-8">
                    <textarea
                      type="text"
                      rows="6"
                      className="rounded-3xl  border-slate-600	focus:outline-yellow duration-100	border-2	w-full py-4   px-4"
                      placeholder="Enter message"
                      {...register("message", {
                        required: true,
                      })}
                      name="message"
                    />
                    {errors.message ? (
                      <>
                        {errors.message.message ? (
                          <>
                            <div className="text-xs text-red-400 font-firs-light ">
                              {errors.message.message}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-xs text-red-400 font-firs-light ">
                              Message cannot be empty
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-light rounded-full px-6 py-2 hover:border-black duration-200 flex gap-3 items-center cursor-pointer"
                  >
                    {loading ? (
                      <>
                        <Spinner />
                      </>
                    ) : (
                      <>Submit</>
                    )}
                    <div>
                      <ArrowLongRightIcon className="h-4 w-4" />
                    </div>
                  </button>
                </form>
                {success && (
                  <div className="p-4 bg-emerald-500 text-center mt-5 rounded-full text-white">
                    Your submission has been sent.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const Spinner = () => {
  return (
    <>
      <svg
        role="status"
        className="inline w-7 h-7 text-white animate-spin dark:text-white/50 fill-white/50 dark:fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </>
  )
}

export default Contact

export const query = graphql`
  query ContactImages {
    contactbg: file(relativePath: { eq: "contact/contact-section.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    contactform: file(relativePath: { eq: "contact/form.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    apple: file(relativePath: { eq: "icons/apple.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    energy: file(relativePath: { eq: "icons/energy.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    enhance: file(relativePath: { eq: "icons/enhance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    esteem: file(relativePath: { eq: "icons/esteem.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gardning: file(relativePath: { eq: "icons/gardning.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    money: file(relativePath: { eq: "icons/money.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    rewards: file(relativePath: { eq: "icons/rewards.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workout: file(relativePath: { eq: "icons/workout.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_1: file(relativePath: { eq: "watawala_gallery/1.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_2: file(relativePath: { eq: "watawala_gallery/2.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_3: file(relativePath: { eq: "watawala_gallery/3.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_4: file(relativePath: { eq: "watawala_gallery/4.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_5: file(relativePath: { eq: "watawala_gallery/5.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_6: file(relativePath: { eq: "watawala_gallery/6.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_7: file(relativePath: { eq: "watawala_gallery/7.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_8: file(relativePath: { eq: "watawala_gallery/8.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_9: file(relativePath: { eq: "watawala_gallery/9.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_10: file(relativePath: { eq: "watawala_gallery/10.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_11: file(relativePath: { eq: "watawala_gallery/11.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_12: file(relativePath: { eq: "watawala_gallery/12.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_13: file(relativePath: { eq: "watawala_gallery/13.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_14: file(relativePath: { eq: "watawala_gallery/14.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_15: file(relativePath: { eq: "watawala_gallery/15.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_16: file(relativePath: { eq: "watawala_gallery/16.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_17: file(relativePath: { eq: "watawala_gallery/17.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_18: file(relativePath: { eq: "watawala_gallery/18.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_19: file(relativePath: { eq: "watawala_gallery/19.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_20: file(relativePath: { eq: "watawala_gallery/20.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_21: file(relativePath: { eq: "watawala_gallery/21.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogOne: file(relativePath: { eq: "pages/home/blog/blogOne.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogTwo: file(relativePath: { eq: "pages/home/blog/blogTwo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogThree: file(relativePath: { eq: "pages/home/blog/blogThree.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogFour: file(relativePath: { eq: "pages/home/blog/blogFour.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogFive: file(relativePath: { eq: "pages/home/blog/blogFive.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogSix: file(relativePath: { eq: "pages/home/blog/blogSix.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workOne: file(relativePath: { eq: "pages/home/school-work/workOne.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workTwo: file(relativePath: { eq: "pages/home/school-work/workTwo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workThree: file(
      relativePath: { eq: "pages/home/school-work/workThree.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workFour: file(
      relativePath: { eq: "pages/home/school-work/workFour.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workFive: file(
      relativePath: { eq: "pages/home/school-work/workFive.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workSix: file(relativePath: { eq: "pages/home/school-work/workSix.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workSeven: file(
      relativePath: { eq: "pages/home/school-work/workSeven.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workEight: file(
      relativePath: { eq: "pages/home/school-work/workEight.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
